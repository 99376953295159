		function calculateMoskitos101(){
	
		document.getElementById("skidka_30").style.height = "1px";
        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(600);
    
		
		ves_pellet = $('#ves_pellet').val();
		type_pellet = $('#type_pellet').val();
		width_pellet = $( "#width_pellet" ).val();
		beg_pellet = $( "#beg_pellet" ).val();
		dostavka_pellet = $( "#dostavka_pellet" ).val();

		
			var basePrice = 0;
			var typePrice = 0;
			var upakovkaPrice = 0;
			var sourcePrice = 0;
			var pelletsPrice =0;
			
			//if (width_pellet =='6'){
			//	typePrice=0;
			//}
			//if (beg_pellet ==1){
			//	upakovkaPrice=260;
			//}
			//if (type_pellet==2){
			//	sourcePrice = 400;
			//}
			
			//new formula 6
			//new formula 6
			if (width_pellet =='6'){
				
				if (beg_pellet ==1){
					pelletsPrice=113;
				}
				else pelletsPrice=105; 
			}
			
			//new formula 8
			if (width_pellet =='8'){
				
				if (beg_pellet ==1){
					pelletsPrice=113;
				}
				else pelletsPrice=105; 
			}			
			
			//new береза
			if (type_pellet==2){
				if (beg_pellet ==1){
					pelletsPrice=125;
				}
				else pelletsPrice=115; 
			}
			
			
			if ( dostavka_pellet == 1){
			
				var vizualSum =0;
			}
			
			else if ( dostavka_pellet == 2){

				var vizualSum ='инд. просчет';
			}			
				
				//var finPrice = ves_pellet*(basePrice+typePrice+upakovkaPrice+sourcePrice);
				var finPrice = ves_pellet*pelletsPrice;
			
			
			oldPrice = finPrice/0.89; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum; //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			//console.log (baseMargin);
			
			
					var type_pellet_text = document.getElementById("type_pellet");
		type_pellet_text = type_pellet_text.options[type_pellet_text.selectedIndex].text;
		console.log(type_pellet_text);
				var dostavka_pellet_text = document.getElementById("dostavka_pellet");
		dostavka_pellet_text = dostavka_pellet_text.options[dostavka_pellet_text.selectedIndex].text;
		console.log(dostavka_pellet_text);	
			
					// записываем в объект - одна жалюзи
		var peletis_item = { 
			ves_pellet: ves_pellet,
			type_pellet: type_pellet_text,
			width_pellet: width_pellet,
			beg_pellet: beg_pellet,
			dostavka_pellet: dostavka_pellet_text,
			finPrice: finPrice,
			vizualSum: vizualSum
		}				
		return peletis_item;	



		
	
	}	
	//<<=======конец=======>>
	
	
	function calculateMoskitos9(){
	
		document.getElementById("skidka_30").style.height = "1px";
        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(600);
    
		
		ves_pellet = $('#ves_pellet').val();
		type_pellet = $('#type_pellet').val();
		width_pellet = $( "#width_pellet" ).val();
		beg_pellet = $( "#beg_pellet" ).val();
		dostavka_pellet = $( "#dostavka_pellet" ).val();

		
			var basePrice = 0;
			var typePrice = 0;
			var upakovkaPrice = 0;
			var sourcePrice = 0;
			var pelletsPrice =0;
			
			//if (width_pellet =='6'){
			//	typePrice=0;
			//}
			//if (beg_pellet ==1){
			//	upakovkaPrice=260;
			//}
			//if (type_pellet==2){
			//	sourcePrice = 400;
			//}
			
			//new formula 6
			if (width_pellet =='6'){
				
				if (beg_pellet ==1){
					pelletsPrice=3490;
				}
				else pelletsPrice=3130; 
			}
			
			//new formula 8
			if (width_pellet =='8'){
				
				if (beg_pellet ==1){
					pelletsPrice=3380;
				}
				else pelletsPrice=3040; 
			}			
			
			//new береза
			if (type_pellet==2){
				if (beg_pellet ==1){
					pelletsPrice=3670;
				}
				else pelletsPrice=3350; 
			}
			
			
			
			if ( dostavka_pellet == 2){
			
				if (ves_pellet <= 2) { dostavka = 500;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 1400;}
				else dostavka = 1400
				var vizualSum =dostavka;
			}
			
			else if ( dostavka_pellet == 3){
			
				if (ves_pellet <= 2) { dostavka = 500;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 1200;}
				else dostavka = 1200
				var vizualSum =dostavka;
			}			
			else if ( dostavka_pellet == 4){
			
				var vizualSum ='инд. просчет';
			}			
			else if ( dostavka_pellet == 5){
			
				var vizualSum ='инд. просчет';
			}
			
			if ( dostavka_pellet == 1){
			
				if (ves_pellet <= 2) { dostavka = 0;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 0;}
				else dostavka = 0
				var vizualSum =dostavka;
			}
			
				
				//var finPrice = ves_pellet*(basePrice+typePrice+upakovkaPrice+sourcePrice);
				var finPrice = ves_pellet*pelletsPrice;
			
			
			oldPrice = finPrice/0.89; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum; //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			//console.log (baseMargin);
			
			
					var type_pellet_text = document.getElementById("type_pellet");
		type_pellet_text = type_pellet_text.options[type_pellet_text.selectedIndex].text;
		console.log(type_pellet_text);
				var dostavka_pellet_text = document.getElementById("dostavka_pellet");
		dostavka_pellet_text = dostavka_pellet_text.options[dostavka_pellet_text.selectedIndex].text;
		console.log(dostavka_pellet_text);	
			
					// записываем в объект - одна жалюзи
		var peletis_item = { 
			ves_pellet: ves_pellet,
			type_pellet: type_pellet_text,
			width_pellet: width_pellet,
			beg_pellet: beg_pellet,
			dostavka_pellet: dostavka_pellet_text,
			finPrice: finPrice,
			vizualSum: vizualSum
		}				
		return peletis_item;	



		
	
	}	
	//<<=======конец=======>>



	function addBasket1(){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
		
			$("#peletis_answer").hide();
			$("#peletis_zakaz").show();
		
			
		}
	function addBasket8(){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
	
			$("#peletis_zakaz").hide();
			
			
			
			
			$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
				var current = data;
				var current = current*1;				
				$("#current").text(data);
				console.log(current);
				
				
				$("#peletis_answer").show();
				var goog = calculateMoskitos9();
				var pelphone = $("#pelphone").val();
				var pelname = $("#pelname").val();
				console.log(goog.vizualSum);
				$("#dialogv52bf36ca52ec3_outputs0").hide();
				if ( current > 0){
				
									$.ajax({
										type:'post',//тип запроса: get,post либо head
										url:'ajax4',//url адрес файла обработчика
										data:{
											'current': current,
											'weight': goog.ves_pellet, 
											'type': goog.type_pellet, 
											'width': goog.width_pellet, 
											'beg': goog.beg_pellet, 
											'dostavka': goog.dostavka_pellet, 
											'pricedostavka': goog.vizualSum,
											'summa': goog.finPrice,									
											'name': pelname,  
											'phone' : pelphone
											},//параметры запроса
										success: function(data){									
											console.log(data);
											}
									});
				}
				else console.log('eror +' +current);
				
				
				}
			})
			
			
			
		}	
	
function addBasket9(){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
	
			$("#peletis_zakaz").hide();
			
			
			
			
			$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
				var current = data;
				var current = current*1;				
				$("#current").text(data);
				console.log(current);
				
				
				$("#peletis_answer").show();
				var goog = calculateMoskitos101();
				var pelphone = $("#pelphone").val();
				var pelname = $("#pelname").val();
				console.log(goog.dostavka_pellet);
				$("#dialogv52bf36ca52ec3_outputs0").hide();
				if ( current > 0){
				
									$.ajax({
										type:'post',//тип запроса: get,post либо head
										url:'https://peletis.com.ua/ajax4',//url адрес файла обработчика
										data:{
											'current': current,
											'weight': goog.ves_pellet, 
											'type': goog.type_pellet, 
											'width': goog.width_pellet, 
											'beg': goog.beg_pellet, 
											'dostavka': goog.dostavka_pellet, 
											'pricedostavka': goog.vizualSum,
											'summa': goog.finPrice,									
											'name': pelname,  
											'phone' : pelphone
											},//параметры запроса
										success: function(data){									
											console.log(data);
											}
									});
				}
				else console.log('eror +' +current);
				
				
				}
			})
			
			
			
		}								
function addBasket11(){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
	
			$("#peletis_zakaz").hide();
			
			
			
			
			$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
				var current = data;
				var current = current*1;				
				$("#current").text(data);
				console.log(current);
				
				
				$("#peletis_answer").show();
				var goog = calculateRuf();
				var pelphone = $("#pelphone").val();
				var pelname = $("#pelname").val();
				console.log(goog.dostavka_pellet);
				$("#dialogv52bf36ca52ec3_outputs0").hide();
				if ( current > 0){
				
									$.ajax({
										type:'post',//тип запроса: get,post либо head
										url:'https://peletis.com.ua/ajax4',//url адрес файла обработчика
										data:{
											'current': current,
											'weight': goog.ves_pellet, 
											'type': goog.type_pellet, 
											'width': goog.width_pellet, 
											'beg': goog.beg_pellet, 
											'dostavka': goog.dostavka_pellet, 
											'pricedostavka': goog.vizualSum,
											'summa': goog.finPrice,									
											'name': pelname,  
											'phone' : pelphone
											},//параметры запроса
										success: function(data){									
											console.log(data);
											}
									});
				}
				else console.log('eror +' +current);
				
				
				}
			})
			
			
			
		}		
	//>>============================функция добавления в закладки страницы =================>>
	function AddToBookmark(a)
	{
	   var title = window.document.title; // запоминаем заголовок активной страницы/вкладки
	   var url = window.document.location; // адрес тоже запоминаем
	 
	   if (window.sidebar) {  // такой объект есть только в Gecko 
		  window.sidebar.addPanel(title, url, ""); // используем его метод добавления закладки
	   }
	   else if (typeof(opera)=="object") {  // есть объект opera?
		  a.rel="sidebar"; // добавлем закладку, смотрите вызов функции ниже
		  a.title=title; 
		  a.url=url; 
		  return true; 
	   }
	   else if(document.all) {  // ну значит это Internet Explorer
		  window.external.AddFavorite(url, title); // используем соответсвующий метод
	   }
	   else {
		  alert("Для добавления страницы в Избранное нажмите Ctrl+D"); // для всех остальных браузеров, в т.ч. Chrome
	   }
	 
	   return false;
	}
	//<<=======конец=======>>
	
	
	//>>============================функция отправлнеия телефона обратной связи =================>>
	function SendPost1() {
		var dream8 = $(".gogol").val();
		console.log (dream8);
		$.ajax({

				type:'post',//тип запроса: get,post либо head

				url:'../../ajax1',//url адрес файла обработчика

				data:{'tel': dream8 },//параметры запроса
				
				success: function(data){
					$('.kokoz').html(data); //выскакивает ответ пользователю
					
					document.getElementsByClassName("popover").style.backgroundColor = "#ff9b03"; //выскакивает просьба о телефоне
				}
		});
	}	
	//<<=======конец=======>>
		function answerNotCorrectNumber() {
			$('html, body').animate({scrollTop: ($('#abs2').offset().top)},500);
			document.getElementById("gogo").style.borderColor = "red";
			document.getElementById("gogo").style.borderWidth = "medium";
			$("#sila span").popover({placement : 'right'});
			$('#sila span').popover('show');
			dream="";			
		}
		
		
		function CorrectAnswer(data) {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){// Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			
		}
		
		
		function CorrectAnswer2(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Киеве.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week === 6 || zvonok_week === 0 || (zvonok_week ===5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
				//console.log(zvonok_time +' '+zvonok_week)
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}

		
function CorrectAnswer2Odessa(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Одессе.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/odessa/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}		

function CorrectAnswer2Dnepr(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Днепропетровске.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/dnepr/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}
function CorrectAnswer2Nikolaev(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Николаеве.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/nikolaev/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}
		
function CorrectAnswer2Zp(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Запорожье.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/zaporozhye/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}		
function CorrectAnswer2Vinnica(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Виннице.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/vinnica/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}				
		
function CorrectAnswer2Harkov(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Харькове.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/kharkov/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}		


function CorrectAnswer2Sumy(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Выберите наиболее удобную для Вас точку самовывоза в Сумах.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/sumy/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}	

function CorrectAnswerRog(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Ознакомтесь и выберите точку выдачи в Кривом Рогу.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/krivoy-rog/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}	

function CorrectAnswerPoltava(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Ознакомтесь и выберите точку выдачи в Полтаве.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/poltava/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}			
		
		
		
		
		
function CorrectAnswerKropivnitskiy(data, dostavka) {
			
			if (dostavka == "самовывоз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Ознакомтесь и выберите точку выдачи в Кропивницком (Кировограде).</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/kropivnitskiy/kontakty?current='+data+'&show=on">выбрать точку самовывоза</a>';
			
			
			
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В понедельник утром, в 10:00, с Вами свяжется менеджер, проверит размеры и согласует время доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. Утром в 10:00 с Вами свяжется <br> менеджер, проверит размеры  и согласует время доставки.</p>';
			}

			else {//В течение 45 минут с Вами свяжется менеджер <br>, проверит размеры и согласует время доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашего заказа: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Спасибо, Мы приняли Ваш заказ. В течение 45 минут с Вами свяжется менеджер, <br>проверит размеры и согласует время доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('добавить ещё в корзину');
			$('#dialogv52bf36ca52ec3_button_text').html('расчитать ещё');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}

		
function CorrectAnswer2ua(data, dostavka) {
			
			if (dostavka == "Самовивіз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Оберіть найбільш зручну для Вас точку самовивозу в Києві.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/ua/kontakty?current='+data+'&show=on">Вибрати точку самовивозу</a>';
			
			
			
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.</p>';
			}

			else {//Протягом 45 хвилин з Вами зв\'яжеться менеджер <br>, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Ми прийняли Ваше замовлення. Протягом 45 хвилин з Вами зв\'яжеться менеджер, <br>перевірить розміри і погодить час доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}

	function sendDostavkaua(dostavka){
		
		$('.js_vybor').hide();
		$('.js_vybor_show_2').hide();
		$('.js_vybor_show').show();
		
		
		var current=$("#current").text()*1;
		if (dostavka =="zdolbun"){ var dostavka = "Здолбунівська 7А, склад №4";}
		else if (dostavka =="borh"){ var dostavka = "Борщагівська 192";}
		else if (dostavka =="alma"){ var dostavka = "Алма-Атинська 2 / 1";}
		else if (dostavka =="yama"){ var dostavka = "Ямська 72, оф. 403";}
		else if (dostavka =="lug"){ var dostavka = "Лугова 16б";}
		else if (dostavka =="akademik"){ var dostavka = "Академіка Заболотного 15";}
		else if (dostavka =="puh"){ var dostavka = "Пухівська 4";}
		else if (dostavka =="raskova"){ var dostavka = "Євгена Северстюка 25А (М. Раскової)";}
		else if (dostavka =="narod"){ var dostavka = "Народного Ополчення 23";}
		
		else if (dostavka =="spusk"){ var dostavka = "Польский спуск 9";}
		else if (dostavka =="kovel"){ var dostavka = "Ковельська 109Б";}
		else if (dostavka =="heva"){ var dostavka = "Шевченка 317";}
		else if (dostavka =="tril"){ var dostavka = "Трильовського 18";}
		else if (dostavka =="ahan"){ var dostavka = "Велика Кільцева 4а";}
		else if (dostavka =="geroi"){ var dostavka = "Героїв Оборони 10, оф. 5";}
		else if (dostavka =="plehanov"){ var dostavka = "Плехановская 134Б";}
		else if (dostavka =="polski"){ var dostavka = "Польский спуск 9";}
		else if (dostavka =="kotov"){ var dostavka = "Дніпропропетровська дорога 99Б";}
		else if (dostavka =="tver"){ var dostavka = "Тверська 1";}
		else if (dostavka =="sav"){ var dostavka = "Савченко 79Б";}	
		else if (dostavka =="jmerinka"){ var dostavka = "Жмеринська 26";}
		else if (dostavka =="cv_1"){ var dostavka = "Незалежності 131";}
		else if (dostavka =="tr_1"){ var dostavka = "Лозовецька 34а";}
		else if (dostavka =="rv_1"){ var dostavka = "Гагаріна 39";}
		else if (dostavka =="gt_1"){ var dostavka = "Вітрука 2";}
		else if (dostavka =="iv_1"){ var dostavka = "Макуха 9";}
		else if (dostavka =="gukova_odesssa_1"){ var dostavka = "Маршала Жукова 4/4";}
		else if (dostavka =="dnepr_odessa_2"){ var dostavka = "Промринок Союз, пав. П-29";}
		else if (dostavka =="minskaya_odessa_3"){ var dostavka = "Мінська 7";}
		else if (dostavka =="czornovola"){ var dostavka = "п-кт В.Чорновола 43";}		
		else if (dostavka =="trud_402"){ var dostavka = "Героїв Труда 30";}
		else if (dostavka =="sport_403"){ var dostavka = "ТЦ Маршал";}
		else if (dostavka =="gagarina_404"){ var dostavka = "Вернадського 1";}
		else { var dostavka = "Самовивіз - точка не вибрана";}
		console.log(current+" "+dostavka);
		
		
		
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//в понеділок вранці, о 10:30
				var vyvod='в понеділок вранці, о 10:30';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//завтра вранці о 10:30
				var vyvod='завтра вранці о 10:30';
			}

			else {//протягом 45 хвилин
				var vyvod='протягом 45 хвилин';
			}
		
		//
		
		
		
		
		
		
		
		
		
		
		
		
		$('#dostavkaVyvod').text(dostavka);
		$('#timeZvonka').text(vyvod);
		
		$('html, body').animate({scrollTop: ($('#otvet').offset().top)},500);
		
		if (current > 10000){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'/ajax6',//url адрес файла обработчика
									data:{'current': current, 'setki_adres' : dostavka},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
		
		
		
		
		
	}		
	
	function sendDostavka(dostavka){
		
		$('.js_vybor').hide();
		$('.js_vybor_show_2').hide();
		$('.js_vybor_show').show();
		
		
		var current=$("#current").text()*1;
		if (dostavka =="zdolbun"){ var dostavka = "Здолбуновская 7А, склад №4";}
		else if (dostavka =="borh"){ var dostavka = "Борщаговская 192";}
		else if (dostavka =="alma"){ var dostavka = "Алма-Атинская 2 / 1";}
		else if (dostavka =="yama"){ var dostavka = "Ямская 72, оф. 403";}
		else if (dostavka =="lug"){ var dostavka = "Луговая 16б";}
		else if (dostavka =="akademik"){ var dostavka = "Академика Заболотного 15";}
		else if (dostavka =="puh"){ var dostavka = "Пуховская 4";}
		else if (dostavka =="raskova"){ var dostavka = "Евгения Северстюка (М. Расковой 25А)";}
		else if (dostavka =="narod"){ var dostavka = "Народного Ополчения, 23";}
		
		else if (dostavka =="spusk"){ var dostavka = "Польский спуск, 9";}
		else if (dostavka =="kovel"){ var dostavka = "Ковельськая, 109Б";}
		else if (dostavka =="heva"){ var dostavka = "Шевченко, 317";}
		else if (dostavka =="tril"){ var dostavka = "Трильовского 18";}
		else if (dostavka =="ahan"){ var dostavka = "Большая Кольцевая 4а";}
		else if (dostavka =="geroi"){ var dostavka = "Героев Обороны 10, оф. 5";}
		else if (dostavka =="plehanov"){ var dostavka = "Плехановская 134Б";}
		else if (dostavka =="polski"){ var dostavka = "Польский спуск 9";}
		else if (dostavka =="kotov"){ var dostavka = "Днепропропетровская дорога 99Б";}
		else if (dostavka =="tver"){ var dostavka = "Тверская 1";}
		else if (dostavka =="sav"){ var dostavka = "Савченко 79Б";}
		else if (dostavka =="jmerinka"){ var dostavka = "Жмеринская 26";}
		else if (dostavka =="cv_1"){ var dostavka = "Незалежності 131";}
		else if (dostavka =="tr_1"){ var dostavka = "Лозовецька 34а";}
		else if (dostavka =="rv_1"){ var dostavka = "Гагаріна 39";}
		else if (dostavka =="gt_1"){ var dostavka = "Вітрука 2";}
		else if (dostavka =="iv_1"){ var dostavka = "Макуха 9";}
		else if (dostavka =="gukova_odesssa_1"){ var dostavka = "Маршала Жукова 4/4";}
		else if (dostavka =="dnepr_odessa_2"){ var dostavka = "Промрынок Союз, пав. П-29";}
		else if (dostavka =="minskaya_odessa_3"){ var dostavka = "Минская 7";}
		else if (dostavka =="czornovola"){ var dostavka = "п-кт В.Чорновола 43";}
		
		else if (dostavka =="trud_402"){ var dostavka = "Героев Труда 30";}
		else if (dostavka =="sport_403"){ var dostavka = "ТЦ Маршал";}
		else if (dostavka =="gagarina_404"){ var dostavka = "Вернадского 1";}
		else if (dostavka =="1402"){ var dostavka = "Квитки-Основьяненко 5";}
		else if (dostavka =="1403"){ var dostavka = "Ю.Савченко 79";}
		else if (dostavka =="1401"){ var dostavka = "Тверская 1";}
		


		
		else { var dostavka = "Самовывоз - точка не выбрана";}
		
		console.log(current+" "+dostavka);
		
		
		
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0){//в понедельник утром, в 10:30
				var vyvod='в понедельник утром, в 10:30';
				console.log(zvonok_time + ' : '+zvonok_week)
			}
				
			else if (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time)){//завтра утром в 10:30
				var vyvod='завтра утром в 10:30';
				console.log(zvonok_time + ' : '+zvonok_week)
			}

				
			else if(zvonok_time >= 18 || zvonok_time < 9){//завтра утром в 10:30
				var vyvod='завтра утром в 10:30';
			}

			else {//в течение 45 минут
				var vyvod='в течение 45 минут';
			}
		
		

		
		
		$('#dostavkaVyvod').text(dostavka);
		$('#timeZvonka').text(vyvod);
		
		$('html, body').animate({scrollTop: ($('#otvet').offset().top)},500);
		
		if (current > 10000){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax6',//url адрес файла обработчика
									data:{'current': current, 'setki_adres' : dostavka},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
		
		
		
		
		
	}

	
	function sendDostavka2(dostavka){
		
		$('.js_vybor').hide();
		$('.js_vybor_show_2').hide();
		$('.js_vybor_show').show();
				
		var current=$("#current").text()*1;
		var dost = dostavka;
		console.log(current+" "+dost);
		
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0){//в понедельник утром, в 10:30
				var vyvod='в понедельник утром, в 10:30';
				console.log(zvonok_time + ' : '+zvonok_week)
			}
				
			else if (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time)){//завтра утром в 10:30
				var vyvod='завтра утром в 10:30';
				console.log(zvonok_time + ' : '+zvonok_week)
			}

				
			else if(zvonok_time >= 18 || zvonok_time < 9){//завтра утром в 10:30
				var vyvod='завтра утром в 10:30';
			}

			else {//в течение 45 минут
				var vyvod='в течение 45 минут';
			}
		
		$('#dostavkaVyvod').text(dost);
		$('#timeZvonka').text(vyvod);
		
		$('html, body').animate({scrollTop: ($('#otvet').offset().top)},500);
		
		if (current > 10000){
			$.ajax({
				type:'post',//тип запроса: get,post либо head
				url:'../ajax6',//url адрес файла обработчика
				data:{'current': current, 'setki_adres' : dost},//параметры запроса
				success: function(data){
								console.log(data);
									}
				});
					
			}	
	}
	
	function sendDostavka2ua(dostavka){
		
		$('.js_vybor').hide();
		$('.js_vybor_show_2').hide();
		$('.js_vybor_show').show();
		
		
		var current=$("#current").text()*1;
		var dost = dostavka;
		console.log(current+" "+dost);
		
		
		
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//в понеділок вранці, о 10:30
				var vyvod='в понеділок вранці, о 10:30';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//завтра вранці о 10:30
				var vyvod='завтра вранці о 10:30';
			}

			else {//протягом 45 хвилин
				var vyvod='протягом 45 хвилин';
			}
		
		//
		
		
		
		
		
		
		
		
		
		
		
		
		$('#dostavkaVyvod').text(dostavka);
		$('#timeZvonka').text(vyvod);
		
		$('html, body').animate({scrollTop: ($('#otvet').offset().top)},500);
		
		if (current > 10000){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'/../ajax6',//url адрес файла обработчика
									data:{'current': current, 'setki_adres' : dostavka},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
		
		
		
		
		
	}		
		
	
	
	//>>============================функция отправлнеия заказа москитной сетки =================>>
	
	function SendPost() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						
						var current = CorrectAnswer2(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}
	
	//<<=======конец=======>>	

	function SendPostua() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						
						var current = CorrectAnswer2ua(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}
	//>>============================калькуляция стоимости москитной сетки =================>>	
	function calculateMoskitos(){
		$("#peletis_answer").hide();
		document.getElementById("skidka_30").style.height = "1px";
        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(800);
      
		
		
		ves_pellet = $('#ves_pellet').val();
		type_pellet = $('#type_pellet').val();
		width_pellet = $( "#width_pellet" ).val();
		beg_pellet = $( "#beg_pellet" ).val();
		dostavka_pellet = $( "#dostavka_pellet" ).val();
		
		//var e = document.getElementById("input_color");
		//colorMosquitoText = e.options[e.selectedIndex].text;
		
		//var e = document.getElementById("input_type");
		//typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		//var basePrice = 115;
		//var baseMargin = 50;
		
		//if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		//else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		//else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		//else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		//else {
		//	var sum=(xWidth*yWidth)/1000000;
									
		//	if (sum < 0.75) {
		//		var vizualSum = sum; 
		//		sum = 0.75;
		//		document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
		//	}
		//	else {
		//		document.getElementById('menhe').innerHTML='';
		//		vizualSum = sum;
		//	}
			
		//	if (typeWindow == 'plastic') { 
		//		
		//		var margin_type = 0;
				
		//		if (sum <= 0.76){ var baseMargin = 51;}				
		//		else if (sum > 0.76 && sum <= 1.02 ){var baseMargin = 70;}			
		//		else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 75;}
		//		else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 75;}
		//		else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 75;}
		//		else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 77;}				
		//		else if (sum > 1.5){ var baseMargin = 85;}
		//		else baseMargin = 59;
		//	}
		//	
		//	else {var margin_type = 40; var baseMargin = 107;}
			
		//	if (colorMosquito == 'white') { var margin_color = 0;}
		//	else {var margin_color = 10;}	
			
		//	if (vidSetki == "okna" ){
		//		var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
		//	}
		//	else if (vidSetki == "dveri" ){
		//		var finPrice = sum*200+160; // формула москитной сетки
		//	}
		
var basePrice = 0;
			var typePrice = 0;
			var upakovkaPrice = 0;
			var sourcePrice = 0;
			var pelletsPrice =0;
			
			//if (width_pellet =='6'){
			//	typePrice=0;
			//}
			//if (beg_pellet ==1){
			//	upakovkaPrice=260;
			//}
			//if (type_pellet==2){
			//	sourcePrice = 400;
			//}
			
			//new formula 6
			if (width_pellet =='6'){
				
				if (beg_pellet ==1){
					pelletsPrice=3490;
				}
				else pelletsPrice=3130; 
			}
			
			//new formula 8
			if (width_pellet =='8'){
				
				if (beg_pellet ==1){
					pelletsPrice=3380;
				}
				else pelletsPrice=3040; 
			}			
			
			//new береза
			if (type_pellet==2){
				if (beg_pellet ==1){
					pelletsPrice=3670;
				}
				else pelletsPrice=3350; 
			}
			
			
			if ( dostavka_pellet == 2){
			
				if (ves_pellet <= 2) { dostavka = 500;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 1400;}
				else dostavka = 1400
				var vizualSum =dostavka;
			}
			
			else if ( dostavka_pellet == 3){
			
				if (ves_pellet <= 2) { dostavka = 500;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 1200;}
				else dostavka = 1200
				var vizualSum =dostavka;
			}			
			else if ( dostavka_pellet == 4){
			
				var vizualSum ='инд. просчет';
			}			
			else if ( dostavka_pellet == 5){
			
				var vizualSum ='инд. просчет';
			}
			
			if ( dostavka_pellet == 1){
			
				if (ves_pellet <= 2) { dostavka = 0;}
				else if (ves_pellet > 2 && ves_pellet <= 5) { dostavka = 0;}
				else dostavka = 0
				var vizualSum =dostavka;
			}
			
				
				//var finPrice = ves_pellet*(basePrice+typePrice+upakovkaPrice+sourcePrice);
				var finPrice = ves_pellet*pelletsPrice;
				 
			
			
			oldPrice = finPrice/0.89; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum; //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			//console.log (baseMargin);
			
			
					// записываем в объект - одна жалюзи
		var peletis_item = { 
			ves_pellet: ves_pellet,
			type_pellet: type_pellet,
			width_pellet: width_pellet,
			beg_pellet: beg_pellet,
			dostavka_pellet: dostavka_pellet,
			finPrice: finPrice,
			vizualSum: vizualSum
		}				
		return peletis_item;	



		
	
	}	
	//<<=======конец=======>>


	
	
	//>>============================калькуляция стоимости москитной сетки =================>>	
	function calculateExport(){
		$("#peletis_answer").hide();
		document.getElementById("skidka_30").style.height = "1px";
        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(800);
      	
		ves_pellet = $('#ves_pellet').val();
		type_pellet = $('#type_pellet').val();
		width_pellet = $( "#width_pellet" ).val();
		beg_pellet = $( "#beg_pellet" ).val();
		dostavka_pellet = $( "#dostavka_pellet" ).val();
		
	
		
			var basePrice = 0;
			var typePrice = 0;
			var upakovkaPrice = 0;
			var sourcePrice = 0;
			var pelletsPrice =0;
			
			//if (width_pellet =='6'){
			//	typePrice=0;
			//}
			//if (beg_pellet ==1){
			//	upakovkaPrice=260;
			//}
			//if (type_pellet==2){
			//	sourcePrice = 400;
			//}
			
			//new formula 6
			if (width_pellet =='6'){
				
				if (beg_pellet ==1){
					pelletsPrice=110;
				}
				else pelletsPrice=102; 
			}
			
			//new formula 8
			if (width_pellet =='8'){
				
				if (beg_pellet ==1){
					pelletsPrice=105;
				}
				else pelletsPrice=98; 
			}			
			
			//new береза
			if (type_pellet==2){
				if (beg_pellet ==1){
					pelletsPrice=120;
				}
				else pelletsPrice=110; 
			}
			
			
			if ( dostavka_pellet == 1){
			
				var vizualSum =0;
			}
			
			else if ( dostavka_pellet == 2){

				var vizualSum ='инд. просчет';
			}			
			
			
			
			
				
				//var finPrice = ves_pellet*(basePrice+typePrice+upakovkaPrice+sourcePrice);
				var finPrice = ves_pellet*pelletsPrice;
				 
			
			
			oldPrice = finPrice/0.89; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum; //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			//console.log (baseMargin);
			
			
					// записываем в объект - одна жалюзи
		var peletis_item = { 
			ves_pellet: ves_pellet,
			type_pellet: type_pellet,
			width_pellet: width_pellet,
			beg_pellet: beg_pellet,
			dostavka_pellet: dostavka_pellet,
			finPrice: finPrice,
			vizualSum: vizualSum
		}				
		return peletis_item;	



		
	
	}	
	//<<=======конец=======>>	
	
	
	
	
	
	var show_otpravka = 0;
	
	function clearspisok () {
		document.getElementById('spisok').innerHTML = '';
		document.getElementById('abs0').innerHTML = "";
		document.getElementById('abs2').innerHTML = "";

		document.getElementById('abs').innerHTML = "";
		document.getElementById('abs1').innerHTML = "";

		document.getElementById('result_setki').style.display = "none";
		document.getElementById('otpravka_setki').style.display = "none";
		

	}
	
	
	
	function addspisok (xWidth, yWidth, county, colorMosquitoText, summa, finPrice, typeMosquitoText, vidSetki) {
		$('.results').html("");
		document.getElementById("current").style.height = "1px";
		document.getElementById("current").style.padding = "0";
		county = county*1 +1;//сумма сеток в штуках
		summa = summa*1 + finPrice*1;//сумма сеток в гривнах
		if (vidSetki == 'dveri') { var vidSetkiText='двери'; var typeMosquitoText=""; }
		else var vidSetkiText='окно';
		
		if (county == 1) {//добавляет первую сетку
		document.getElementById('spisok').innerHTML = county+'. Cетка на '+ typeMosquitoText + '&nbsp;' + vidSetkiText +': ' + xWidth  + 'мм x ' + yWidth + ' мм &nbsp;- цвет ' + colorMosquitoText + '&nbsp;' + '= &nbsp;' + finPrice + '&nbsp;' + 'грн.';
		}
	
		else if (county > 1) {//добавляет вторую и далее сетки
		document.getElementById('spisok').innerHTML = document.getElementById('spisok').innerHTML + "\n" + county + '. Cетка на ' + typeMosquitoText + '&nbsp;' + vidSetkiText +': ' + xWidth  + 'мм x ' + yWidth + ' мм &nbsp;- цвет ' + colorMosquitoText + '&nbsp;' + '= &nbsp;' + finPrice + '&nbsp;' + 'грн.';
		}
		
		document.getElementById('abs0').innerHTML = summa;
		document.getElementById('abs2').innerHTML = county;
		document.getElementById('result_setki').style.display = 'block';
				
	}
	
	function otpravka() {
		
		document.getElementById('otpravka_setki').style.display = "block";		
		
	}
	
	function showGoogleMaps() {
	
		var position = posi;
		var street = streetPosi;
		var latLng = new google.maps.LatLng(position[0], position[1]);
 
		var mapOptions = {
			zoom: 17, // initialize zoom level - the max value is 21
			streetViewControl: false, // hide the yellow Street View pegman
			scaleControl: true, // allow users to zoom the Google Map
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			center: latLng
		};
 
		map = new google.maps.Map(document.getElementById(street),
			mapOptions);
 
		// Show the default red marker at the location
		marker = new google.maps.Marker({
			position: latLng,
			map: map,
			draggable: false,
			animation: google.maps.Animation.DROP
		});
	
	} 
		
	function calculateDveri() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitos();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}

	function calculateOkna() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitos();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}	
	
//>>============================функция просчета АНТИКОШКИ =================>>	


	function antiKoshka(instr) {

		var widthSetki  = $("#x_width").val();		
		var heightSetki = $("#y_width").val();
		
		var widthSetki = zapiataja(widthSetki); // замена запятой на точки - преобразовываем в число
		var heightSetki = zapiataja(heightSetki); // замена запятой на точки - преобразовываем в число
				
		var colorSetki  = $("#input_color").val();
		var typeSetki   = $("#input_type").val();
		
		console.log('ширина: '+ widthSetki +'; высота: '+heightSetki+'; цвет сетки: '+ colorSetki+'; тип сетки: '+typeSetki);
		
		//errorCheck() - проверка ошибок ввода
		var errorMaster = errorCheck(widthSetki,heightSetki,colorSetki,typeSetki); //проверяем ошибки ввода
		
		//errorBase() - проверка по базе ошибок
		if (errorMaster !== 0) {//если есть ошибки уведомляем 
			return console.log('ОШИБКА= '+errorBase(errorMaster[1]));
		}	
		
		//calcAntikoshka() - подсчет стоимости сетки и площадь
		var azz = calcAntikoshka(widthSetki,heightSetki,colorSetki,typeSetki);
		//console.log('ogo= '+azz[2]);
		
		if (instr == 1){
			//showCalc() - вывод в браузер результатов просчета
			showCalc(azz[0],azz[2]);
		}
		
		
		return azz;
	}
	
//>>============================функция вывода в браузер результатов просчета =================>>	
	function showCalc(vizualSum, finPrice){
		
		$("#skidka_30").hide();
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "155px");
		
		$("#out_1").text(finPrice);
		$("#out_2").text(vizualSum);
	}
	
//>>============================функция замены запятой на точку=================>>	
	
	function zapiataja(znaczenie){// замена запятой на точки - преобразовываем в число
		return znaczenie.replace(",", ".")*1;
	}
	
	
//>>============================функция ПОИСКА ОШИБОК ВВОДА=================>>		
	
	function errorCheck(widthSetki,heightSetki,colorSetki,typeSetki){
		
		var errorSum = 0;		
		
		if (isNaN(widthSetki)){//проверка, число ли это
			errorSum=[1, 1000, widthSetki];
			return errorSum;
		}			
		else if (widthSetki <= 5) {//проверка, может человек указал ширину в метрах
				errorSum=[1, 1010, widthSetki];
				return errorSum;
			}	
		else if (widthSetki < 200) {//проверка, может человек указал ширину в сантиметрах
				errorSum=[1, 1011, widthSetki];
				return errorSum;
			}
		else if (widthSetki > 3000) {//проверка, может человек указал ширину в сантиметрах
				errorSum=[1, 1012, widthSetki];
				return errorSum;
			}
			
			
		if (isNaN(heightSetki)){//проверка, число ли это
			errorSum=[1, 1000, heightSetki];
			return errorSum;
		}				
		else if (heightSetki <= 5) {//проверка, может человек указал высоту в метрах
				errorSum=[1, 1020, heightSetki];
				return errorSum;
		}	
		else if (heightSetki < 200) {//проверка, может человек указал высоту в сантиметрах
				errorSum=[1,1021, heightSetki];
				return errorSum;
		}
		else if (heightSetki > 3000) {//проверка, может человек указал ширину в сантиметрах
				errorSum=[1, 1022, heightSetki];
				return errorSum;	
		}
		
		return errorSum;
	}

//>>============================функция БАЗА ОШИБОК=================>>	
	function errorBase(errorFound){
	
		
		var errorBase = {
			1000: 'К сожалению это не число. Мы не сможем посчитать стоимость. Допустимый размер сетки в мм от 200 до 3000',
			
			1010: 'Ширина сетки должна быть БОЛЬШЕ чем 5 мм. Минимальная ширина: 200 мм. Возможно вы указали ширину в МЕТРАХ?', 
			1011: 'Ширина сетки должна быть БОЛЬШЕ чем 200 мм. Минимальная ширина: 200 мм. Возможно вы указали ширину в сантиметрах?',
			1012: "Ширина сетки должна быть МЕНЬШЕ, чем 3 м (3000мм). Возможно вы ошиблись?",
			
			1020: 'Высота сетки должна быть БОЛЬШЕ чем 5 мм. Минимальная ширина: 200 мм. Возможно вы указали высоту в МЕТРАХ?',
			1021: 'Высота сетки должна быть БОЛЬШЕ чем 200 мм. Минимальная ширина: 200 мм. Возможно вы указали высоту в сантиметрах?',
			1022: 'Высота сетки должна быть МЕНЬШЕ, чем 3 м (3000мм). Возможно вы ошиблись?'
		}
		
		return errorBase[errorFound];
		
	}
	


//>>============================функция Калькулятор сетки Антикошка=================>>		
	function calcAntikoshka(widthSetki,heightSetki,colorSetki,typeSetki) {

		var sum=(widthSetki*heightSetki)/1000000;
		var visualSum = sum.toFixed(2); //округление площади до 2х знаков.


		var baseKohka = 220; // базоавя антикошка
		var baseAnvis = 110; // базовая анвис
		var baseMargin = 120; // базовая наценка
		var kruczki = 10; //12 крючков
				
		if (sum < 0.75) {
			sum = 0.75; 
			baseMargin = 120; 
			errorSum = 1;
		}		
		else if (sum > 0.75 && sum <= 0.85 ){ 
			baseMargin = 120; 
			errorSum = 0;
		}			
		else if (sum > 0.85 && sum <= 1.05){ 
			baseMargin = 130; 
			errorSum = 0;
		}				
		else if (sum > 1.05) { 
			baseMargin = 140; 
			errorSum = 0;
		}		
		
		var marginType = 0;
		var marginColor = 0;
		
		if (typeSetki != 'plastic') { 
			var marginType = 40;	
		}
					
		if (colorSetki != 'white') { 
			var marginColor = 10;
		}
		
		var finPrice = sum*(baseAnvis + marginColor + marginType + baseKohka) + kruczki + baseMargin; // формула москитной сетки					
		
		finPrice = Math.round(finPrice); // округление стоимости до целого числа
		
			console.log('Цена сетки: '+ finPrice +'; Наценка за цвет: '+marginColor+'; Наценка за тип: '+ marginType);
	
		var setkiMassiv=[visualSum, errorSum, finPrice, widthSetki, heightSetki, colorSetki, typeSetki];
			console.log('Площадь сетки: '+ setkiMassiv[0] +'; Есть ли уведомление: '+setkiMassiv[1]+'; Цена: '+ setkiMassiv[2]);
			
		return setkiMassiv;	
	}
	
// добавляем в корзину

		function addBasketKoshka(){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
			//ga('send', 'event', 'button', 'click', 'add');//гугл цель 3	
			
			var $speech = $("#crop4"); // выпадающий список
			$speech.css('height', "730px");
			
			
			var county = $('#abs2').text();
			var summa = $('#abs0').text();
			var finPrice = $('#out_1').text();
			var odnaSetka = antiKoshka(0);
			
			if (county != "") {county = county*1;}
			if (county == "") {county = 0;}
			if (summa != "") {summa = summa*1;}
			if (summa == "") {summa = 0;}			
		
			addspisok(odnaSetka[3], odnaSetka[4], county, odnaSetka[5], summa, finPrice, odnaSetka[6]); // функция добавления сеток
			
		}	
		
//разное

function show_adress(id){
	var gogo="#"+id;
	$(gogo).show();
	console.log(id);
	
}		
	
//>>============================Одесса =================>>	
function calculateMoskitosOdessa(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 120;
		//var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.8) {
				var vizualSum = sum; 
				sum = 0.8;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,8м<sup>2</sup>, считаются как 0,8м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}


			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.81){ var baseMargin = 66;}				
				else if (sum > 0.81 && sum <= 1.02 ){var baseMargin = 70;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 70;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 70;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 70;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 72;}				
				else if (sum > 1.5){ var baseMargin = 80;}
				else baseMargin = 70;
			}


			
			else {var margin_type = 0; var baseMargin = 76;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 0;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*200+160; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaOdessa() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosOdessa();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriOdessa() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitos();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Одесса =================>>
	
	function SendPostOdessa() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Odessa(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Одесса, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	
	
//>>============================Львів =================>>	
function calculateMoskitosLviv(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 130;
		//var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Висота сітки має бути МЕНЬШЕ ніж 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сітки має бути МЕНЬШЕ ніж 3 м');}
		else if (yWidth < 200) {alert('Висота сітки має бути БІЛЬШЕ ніж 20 см');}
		else if (xWidth < 200) {alert('Ширина сітки має бути БІЛЬШЕ ніж 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.8) {
				var vizualSum = sum; 
				sum = 0.8;
				document.getElementById('menhe').innerHTML = 'Сітки меньше 0,8м<sup>2</sup>, рахуються як 0,8м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}

			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.81){ var baseMargin = 45;}				
				else if (sum > 0.81 && sum <= 1.02 ){var baseMargin = 55;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 59;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 61;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 63;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 65;}				
				else if (sum > 1.5){ var baseMargin = 70;}
				else baseMargin = 57;
			}			
			else {var margin_type = 40; var baseMargin = 76;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*200+160; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaLviv() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москітна сітка від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosLviv();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriLviv() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москітна сітка на двері від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosLviv();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Львов =================>>
	
	function SendPostLvivUa() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerOdessaUa(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						var setki_adres = "Львів, " + $("#gogo_uk").val();
						
						
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax_lviv',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}
	
	
function CorrectAnswerOdessaUa(data, dostavka) {
			
			if (dostavka == "Самовивіз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Оберіть найбільш зручну для Вас точку самовивозу у Львові.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/ua/lviv/kontakty?current='+data+'&show=on">Вибрати точку самовивозу</a>';
			
			
			
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.</p>';
			}

			else {//Протягом 45 хвилин з Вами зв\'яжеться менеджер <br>, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Ми прийняли Ваше замовлення. Протягом 45 хвилин з Вами зв\'яжеться менеджер, <br>перевірить розміри і погодить час доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}	
		
//>>============================ХАРЬКОВ =================>>	
function calculateMoskitosHarkov(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 110;
		var baseMargin = 57;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.7) {
				var vizualSum = sum; 
				sum = 0.7;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,7м<sup>2</sup>, считаются как 0,7м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.71){ var baseMargin = 56;}				
				else if (sum > 0.71 && sum <= 1.02 ){var baseMargin = 59;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 59;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 59;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 62;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 64;}				
				else if (sum > 1.5){ var baseMargin = 66;}
				else baseMargin = 59;
			}			

			
			else {var margin_type = 0; var baseMargin = 76;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 10;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*117+160; // формула москитной сетки
			}
			oldPrice = finPrice/0.82; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaHarkov() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosHarkov();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriHarkov() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitos();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Одесса =================>>
	
	function SendPostHarkov() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#harkov_open').show();
						//var current = CorrectAnswer1(data); //получаем номер заказа
						var current = CorrectAnswer2Harkov(data,dostavka);
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Харьков, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	



//>>============================Днепр =================>>	
function calculateMoskitosDnepr(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 100;
		//var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.75) {
				var vizualSum = sum; 
				sum = 0.75;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}

			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.76){ var baseMargin = 58;}				
				else if (sum > 0.76 && sum <= 1.02 ){var baseMargin = 63;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 66;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 68;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 70;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 70;}				
				else if (sum > 1.5){ var baseMargin = 70;}
				else baseMargin = 58;
			}
	
			else {var margin_type = 0; var baseMargin = 72;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin+6; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+160; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaDnepr() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosDnepr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriDnepr() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosDnepr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Одесса =================>>
	
	function SendPostDnepr() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Dnepr(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Днепропетровск, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	// отправка заказа Николаев
	
	function SendPostNikolaev() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Nikolaev(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Николаев, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	

//>>============================Николаев =================>>	
function calculateMoskitosNikolaev(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 110;
		var baseMargin = 46;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.75) {
				var vizualSum = sum; 
				sum = 0.75;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
			
				if (sum > 0.75 && sum <= 0.85 ){var baseMargin = 55;}			
				else if (sum > 0.85){ var baseMargin = 60;}				
				else if (sum <= 0.75){ var baseMargin = 52;}
				
			}
			
			
			
			
			
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaNikolaev() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosDnepr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriNikolaev() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosDnepr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	

//>>============================Запорожье=================>>

	// отправка заказа Запорожье
	
	function SendPostZp() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Zp(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Запорожье, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	

//>>============================ Запорожье =================>>	
function calculateMoskitosZp(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 4.4 * 26.5;
		var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.7) {
				var vizualSum = sum; 
				sum = 0.7;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,7м<sup>2</sup>, считаются как 0,7м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
			
				if (sum > 0.75 && sum <= 0.85 ){var baseMargin = 55;}			
				else if (sum > 0.85){ var baseMargin = 60;}				
				else if (sum <= 0.75){ var baseMargin = 52;}
			}
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaZp() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosZp();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriZp() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosZp();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	


//>>============================Кривой РОГ=================>>

	// отправка заказа Запорожье
	
	function SendPostRog() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerRog(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Кривой Рог, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	


function calculateMoskitosRog(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 110;
		var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.75) {
				var vizualSum = sum; 
				sum = 0.75;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
		
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.75){ var baseMargin = 55;}				
				else if (sum > 0.75 && sum <= 1.02 ){var baseMargin = 57;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 59;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 59;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 62;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 64;}				
				else if (sum > 1.5){ var baseMargin = 66;}
				else baseMargin = 59;
			}
			
			
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 20;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaRog() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosRog();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriRog() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosRog();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}		
	
	
	
	
	
	
	

//>>============================ Кировоград =================>>
	
	// отправка заказа Кировоград
	
	function SendPostKropivnitskiy() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerKropivnitskiy(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Кропивницкий, " + $("#gogo_uk").val();
						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	
	// калькуляция заказа в Кировограде
	
	function calculateMoskitosKropivnitskiy(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 100;
		var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.75) {
				var vizualSum = sum; 
				sum = 0.75;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
		
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.75){ var baseMargin = 55;}				
				else if (sum > 0.75 && sum <= 1.02 ){var baseMargin = 57;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 59;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 59;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 62;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 64;}				
				else if (sum > 1.5){ var baseMargin = 66;}
				else baseMargin = 59;
			}
			
			
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 10;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	
	// смена калькуляии двери на окно
	function calculateOknaKropivnitskiy() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosKropivnitskiy();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}
	
	// смена калькуляии окна на дверь
	function calculateDveriKropivnitskiy() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosKropivnitskiy();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}			
	
//<<============================ Кировоград =================<<	
	
	
//>>============================ Полтава =================>>
	
	// отправка заказа Полтава
	
	function SendPostPoltava() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerPoltava(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Полтава, " + $("#gogo_uk").val();
						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	
	// калькуляция заказа в Poltava
	
	function calculateMoskitosPoltava(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 117;
		var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.75) {
				var vizualSum = sum; 
				sum = 0.75;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,75м<sup>2</sup>, считаются как 0,75м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
		
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
				
				if (sum <= 0.75){ sum = 1; var baseMargin = 55; basePrice = 94;}				
				else if (sum > 0.75 && sum <= 1.02 ){var baseMargin = 57;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 59;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 59;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 62;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 64;}				
				else if (sum > 1.5){ var baseMargin = 66;}
				else baseMargin = 59;
			}
			
			
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	
	// смена калькуляии двери на окно
	function calculateOknaPoltava() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosPoltava();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}
	
	// смена калькуляии окна на дверь
	function calculateDveriPoltava() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosPoltava();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}			
	
//<<============================конец Полтава=================<<	

























	
	
	
	
	
	
//>>============================Винница=================>>

	// отправка заказа Винница
	
	function SendPostVinnica() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Vinnica(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "Винница, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	

//>>============================ Винница =================>>	
function calculateMoskitosVinnica(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 100;
		var baseMargin = 60;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.7) {
				var vizualSum = sum; 
				sum = 0.7;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,7м<sup>2</sup>, считаются как 0,7м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
			
				if (sum > 0.75 && sum <= 0.85 ){var baseMargin = 60;}			
				else if (sum > 0.85){ var baseMargin = 60;}				
				else if (sum <= 0.75){ var baseMargin = 60;}
			}
			
			else {var margin_type = 0; var baseMargin = 70;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaVinnica() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosVinnica();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriVinnica() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosVinnica();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	



//>>============================Сумы=================>>

	// отправка заказа Сумы
	
	function SendPostSumy() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'ajax',
				success: function(data){
					
					
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswer2Sumy(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						
						
						var setki_adres = "СУмы, " + $("#gogo_uk").val();
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'ajax4',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}	

	

//>>============================ Cумы =================>>	
function calculateMoskitosSumy(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 115;
		var baseMargin = 53;
		
		if (yWidth > 3000) {alert('Высота сетки должна быть МЕНЬШЕ, чем 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сетки должна быть МЕНЬШЕ, чем 3 м');}
		else if (yWidth < 200) {alert('Высота сетки должна быть БОЛЬШЕ, чем 20 см');}
		else if (xWidth < 200) {alert('Ширина сетки должна быть БОЛЬШЕ, чем 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
									
			if (sum < 0.7) {
				var vizualSum = sum; 
				sum = 0.7;
				document.getElementById('menhe').innerHTML = 'Сетки меньше 0,7м<sup>2</sup>, считаются как 0,7м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;
			
				if (sum <= 0.85 ){
					var baseMargin = 53;
					}			
				else { 
					var baseMargin = 57;
					}					
			}			
			else {
				var margin_type = 0; 
				var baseMargin = 70;
				}
			
			if (colorMosquito == 'white') { 
				var margin_color = 0;
				}
			else {
				var margin_color = 15;
				}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1 ){ sum =1;}
				var finPrice = sum*160+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaSumy() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosSumy();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriSumy () {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москитная сетка от фабрики Москитос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosSumy();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}






	

//>>============================Чернівці =================>>	
function calculateMoskitosCv(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 115;
		//var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Висота сітки має бути МЕНЬШЕ ніж 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сітки має бути МЕНЬШЕ ніж 3 м');}
		else if (yWidth < 200) {alert('Висота сітки має бути БІЛЬШЕ ніж 20 см');}
		else if (xWidth < 200) {alert('Ширина сітки має бути БІЛЬШЕ ніж 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
			var perimetr=(xWidth*2 + yWidth*2)/1000;
									
			if (sum < 0.1) {
				var vizualSum = sum; 
				sum = 0.1;
				document.getElementById('menhe').innerHTML = 'Сітки меньше 0,1м<sup>2</sup>, рахуються як 0,1м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;

				if (sum <= 0.7){ var baseMargin = 49;}				
				else if (sum > 0.76 && sum <= 1.02 ){var baseMargin = 50;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 52;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 52;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 52;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 52;}				
				else if (sum > 1.5){ var baseMargin = 52;}
				else baseMargin = 55;
				
				
				
			}
			
			
			
			
			
			
			else {var margin_type = 0; var baseMargin = 65;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				//var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
				var finPrice = perimetr*28+baseMargin+margin_color; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1.2 ){ sum =1.2;}
				var finPrice = perimetr*59+100; // формула москитной сетки
				//var finPrice = sum*200+160; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaCv() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москітна сітка від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosCv();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriCv() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москітна сітка на двері від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosCv();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Черновцы =================>>
	
	function SendPostCvUa() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerCvUa(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						var setki_adres = "Чернівці, " + $("#gogo_uk").val();
						
						
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax_lviv',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}
	
	
function CorrectAnswerCvUa(data, dostavka) {
			
			if (dostavka == "Самовивіз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Оберіть найбільш зручну для Вас точку самовивозу у Чернівцях.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/ua/chernivtsi/kontakty?current='+data+'&show=on">Вибрати точку самовивозу</a>';
			
			
			
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.</p>';
			}

			else {//Протягом 45 хвилин з Вами зв\'яжеться менеджер <br>, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Ми прийняли Ваше замовлення. Протягом 45 хвилин з Вами зв\'яжеться менеджер, <br>перевірить розміри і погодить час доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}		
		
		
//>>============================Тернопіль =================>>	
function calculateMoskitosTr(){
		
		document.getElementById("skidka_30").style.height = "1px";
        
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "220px");
		
		xWidth = $('#x_width').val();
		yWidth = $('#y_width').val();
		typeWindow = $( "#input_type" ).val();
		colorMosquito = $( "#input_color" ).val();
		vidSetki = $( "#vid_setki" ).val();
		
		var e = document.getElementById("input_color");
		colorMosquitoText = e.options[e.selectedIndex].text;
		
		var e = document.getElementById("input_type");
		typeMosquitoText = e.options[e.selectedIndex].text;
		 	
		var basePrice = 125;
		//var baseMargin = 50;
		
		if (yWidth > 3000) {alert('Висота сітки має бути МЕНЬШЕ ніж 3 м');}
		
		else if (xWidth > 3000) {alert('Ширина сітки має бути МЕНЬШЕ ніж 3 м');}
		else if (yWidth < 200) {alert('Висота сітки має бути БІЛЬШЕ ніж 20 см');}
		else if (xWidth < 200) {alert('Ширина сітки має бути БІЛЬШЕ ніж 20 см');}
		
		else {
			var sum=(xWidth*yWidth)/1000000;
			//var perimetr=(xWidth*2 + yWidth*2)/1000;
									
			if (sum < 0.7) {
				var vizualSum = sum; 
				sum = 0.7;
				document.getElementById('menhe').innerHTML = 'Сітки меньше 0,7м<sup>2</sup>, рахуються як 0,7м<sup>2</sup>'; 
			}
			else {
				document.getElementById('menhe').innerHTML='';
				vizualSum = sum;
			}
			
			if (typeWindow == 'plastic') { 
				
				var margin_type = 0;

				if (sum <= 0.7){ var baseMargin = 50;}				
				else if (sum > 0.76 && sum <= 1.02 ){var baseMargin = 56;}			
				else if (sum > 1.02 && sum <= 1.04 ){ var baseMargin = 58;}
				else if (sum > 1.04 && sum <= 1.08 ){ var baseMargin = 60;}
				else if (sum > 1.08 && sum <= 1.3){ var baseMargin = 62;}
				else if (sum > 1.3 && sum <= 1.5){ var baseMargin = 64;}				
				else if (sum > 1.5){ var baseMargin = 70;}
				else baseMargin = 54;
				
				
			}
			
			else {var margin_type = 0; var baseMargin = 61;}
			
			if (colorMosquito == 'white') { var margin_color = 0;}
			else {var margin_color = 5;}	
			
			if (vidSetki == "okna" ){
				var finPrice = sum*(basePrice+margin_color+margin_type)+baseMargin; // формула москитной сетки
				//var finPrice = perimetr*26+baseMargin+margin_color; // формула москитной сетки
			}
			else if (vidSetki == "dveri" ){
				if (sum < 1.2 ){ sum =1.2;}
				//var finPrice = perimetr*59+baseMargin+margin_color; // формула москитной сетки
				var finPrice = sum*210+100; // формула москитной сетки
			}
			oldPrice = finPrice/0.75; oldPrice = Math.round(oldPrice);  // старая цена
			finPrice = Math.round(finPrice); // округление стоимости до целого числа
			
			document.getElementById('out_1').innerHTML = finPrice;
			document.getElementById('out_4').innerHTML = oldPrice;
			document.getElementById('out_2').innerHTML = vizualSum.toFixed(2); //вывод площади, округляем до 2 десятичных
			$("html, body").delay(400).animate({scrollTop: $('#dialogv52bf36ca52ec3__line').offset().top }, 1000);
			console.log (baseMargin);
			console.log (xWidth);
			console.log (yWidth);
			console.log (vizualSum);
			console.log (finPrice);
			console.log (typeWindow);
			console.log (colorMosquito);
			console.log (colorMosquitoText);
			console.log (typeMosquitoText);
			console.log (vidSetki);
			
			
		}
	}	
	//<<=======конец=======>>

function calculateOknaTr() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka.jpg"  width="270" height="417" alt="Москітна сітка від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#939190";
		document.getElementById('dveri_link').style.color = "#00b3c2";
		document.getElementById('okna_link').style.fontSize = "24px";
		document.getElementById('dveri_link').style.fontSize = "17px";
		document.getElementById('tip_okna').style.display = "block";
		document.getElementById('vyborcveta').style.marginBottom = "15px";
		
		$("#x_width").val(800);
		$("#y_width").val(1020);
		$("#vid_setki").val('okna');
		calculateMoskitosTr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
		
	
	}

function calculateDveriTr() {
		var dveriImg = '<img src="../img/moskitos_moskitnaya_setka_na_dveri.jpg"  width="270" height="417" alt="Москітна сітка на двері від фабрики Москітос" class="top_img_article" style="float:left;">';
		
		document.getElementById('moskitos_img').innerHTML = dveriImg;
		document.getElementById('okna_link').style.color = "#00b3c2";
		document.getElementById('dveri_link').style.color = "#939190";
		document.getElementById('okna_link').style.fontSize = "17px";
		document.getElementById('dveri_link').style.fontSize = "24px";
		document.getElementById('tip_okna').style.display = "none";
		document.getElementById('vyborcveta').style.marginBottom = "60px";
		$("#x_width").val(675);
		$("#y_width").val(2180);
		$("#vid_setki").val('dveri');
		calculateMoskitosTr();
		var $speech = $("#dialogv52bf36ca52ec3_outputs0");
        $speech.css('height', "0px");
		document.getElementById("skidka_30").style.height = "100px";
	}	
	
//>>============================функция отправлнеия заказа москитной сетки Черновцы =================>>
	
	function SendPostTrUa() {
		
		goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();
		
		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		
		$.ajax({
				type:'get',
				url:'../../ajax',
				success: function(data){
						
						var dostavka = $('input[name="optionsRadios"]:checked').val();
						$('#odessa_open').show();
						var current = CorrectAnswerTrUa(data,dostavka); //получаем номер заказа
						var dream = $("#gogo").val();					
						var dream1 = $("#gogo1").val();
						var name_surname =  $("#name_surname").val();
						var setki_summa =  $("#abs0").text();
						var setki_kolichestvo =  $("#abs2").text();
						var gogo = $('#citylink').text();
						var setki_adres = gogo+", " + $("#gogo_uk").val();
						
						
						
						


						
						var dream2 = $('input[name="optionsRadiosуr"]:checked').val();
						var dream3 = $('input[name="optionsRadios"]:checked').val();
						var dream7 = typeWindow;
						var dream4 = $("#spisok").val();

						if (dream3 == "3"){
							dream3 = 'доставка Новой Почтой (35 - 50 грн)';
							var dream5 = $("#other_city").val();
							var dream6 = document.getElementById('nova');
							dream6 = dream6.value;
					
							if ( dream5.length > 2){
								dream3 = 'Доставка Новой Почтой в '+dream5;
								}
							else{
								dream3 = 'Доставка Новой Почтой по адресу: '+dream6;
								}
						}
						clearspisok();
						if (dream.length >4){
								$.ajax({
									type:'post',//тип запроса: get,post либо head
									url:'../../ajax_lviv',//url адрес файла обработчика
									data:{'current': current, 'z': dream, 'y': dream1, 'y2': dream2, 'y3': dream3, 'y4': dream4, 'y7' : dream7, 'name_surname' : name_surname, 'setki_summa' : setki_summa, 'setki_kolichestvo' : setki_kolichestvo, 'setki_adres' : setki_adres},//параметры запроса
									success: function(data){
										
										console.log(data);
									}
								});
					
						}
				}	
		});
	}
	
	
function CorrectAnswerTrUa(data, dostavka) {
			
			if (dostavka == "Самовивіз"){
				
				console.log("самовывоз точно");
				
			$('#sila span').popover('hide');
			var gogo = $('#citylink').text();
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;">Оберіть найбільш зручну для Вас точку самовивозу.</p><a class="btn btn-large btn-success" style="margin-top:10px;" href="/ua/'+gogo+'/kontakty?current='+data+'&show=on">Вибрати точку самовивозу</a>';
			
			
			
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "170px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
			$('.results').html(vyvod);			
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;	
				
				
				
				
				
				
			}
			else {
			$('#sila span').popover('hide');
			var currentTime = new Date();
			var zvonok_time = currentTime.getHours();
			var zvonok_week = currentTime.getDay();
			if (zvonok_week == 6 || zvonok_week == 0 || (zvonok_week ==5 && (zvonok_time >= 18 || 9 < zvonok_time))){//Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. У понеділок вранці, о 10:00, з Вами зв\'яжеться менеджер, перевірить розміри і погодить час доставки.</p>';
			}
										
			else if(zvonok_time >= 18 || zvonok_time < 9){//Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Дякуємо, ми прийняли Ваше замовлення. Вранці о 10:00 з Вами зв\'яжеться <br> менеджер, перевірить розміри і погодить час доставки.</p>';
			}

			else {//Протягом 45 хвилин з Вами зв\'яжеться менеджер <br>, перевірить розміри і погодить час доставки.
				var vyvod='<p style="padding-top:10px;"> Номер Вашого замовлення: '+data+'<br></p><p style="color: white !important; font-size:16px !important; line-height: 23px;"> Ми прийняли Ваше замовлення. Протягом 45 хвилин з Вами зв\'яжеться менеджер, <br>перевірить розміри і погодить час доставки.</p>';
			}
			
			$('.results').html(vyvod);
			$('#in4').html('додати ще у кошик');
			$('#dialogv52bf36ca52ec3_button_text').html('розрахувати ще');
						
			document.getElementById("crop4").style.height = "1px";
			document.getElementById("current").style.height = "120px";
			document.getElementById("current").style.padding = "20px";
			document.getElementById("current").style.backgroundColor = "#ff9b03";
			document.getElementById("gogo").style.borderColor = "#cccccc";
			document.getElementById("gogo").style.borderWidth = "1px";
						
			
					
			$('html, body').animate({scrollTop: ($('#dialogv52bf36ca52ec3_button_text').offset().top)},500);
			return data;
			}
			
		}	

////// okonnye ruczki


		function addBasket2(id){//добавляем в корзину сетки, считаем сумму, гугл цель 3 создаем
			ga('send', 'event', 'button', 'click', 'add');//гугл цель 3	
			var ri =[];
			ri[101]=['HOPPE Tokyo', 'белая', '85'];
			ri[102]=['HOPPE Tokyo', 'бронзовая', '85'];
			ri[103]=['HOPPE Tokyo', 'коричневая', '85'];
			
			ri[201]=['HOPPE New York', 'белая', '95'];
			ri[202]=['HOPPE New York', 'бронзовая', '95'];
			ri[203]=['HOPPE New York', 'коричневая', '95'];
			
			ri[301]=['HOPPE Atlanta', 'белая', '95'];
			ri[302]=['HOPPE Atlanta', 'бронзовая', '95'];
			ri[303]=['HOPPE Atlanta', 'коричневая', '95'];
			
			ri[401]=['HOPPE Lima', 'белая', '79'];
			ri[402]=['HOPPE Lima', 'бронзовая', '79'];
			ri[403]=['HOPPE Lima', 'коричневая', '79'];
			
			var brand = ri[id][0];
			var color = ri[id][1];
			var price = ri[id][2];
			
			
			$speech = $("#crop4"); // выпадающий список
			$speech.css('height', "850px");
			$('html, body').animate({scrollTop: ($('#korzina').offset().top)},500);
			
			var county = document.getElementById('abs2').innerHTML;
			var summa = document.getElementById('abs0').innerHTML;
			finPrice = price;
			
			if (county != "") {county = county*1;}
			if (county == "") {county = 0;}
			if (summa != "") {summa = summa*1;}
			if (summa == "") {summa = 0;}			
		
			addspisok2(brand, color, price, county, summa); // функция добавления сеток
			
		}
		
	function addspisok2 (brand, color, price, county, summa) {
		$('.results').html("");
		document.getElementById("current").style.height = "1px";
		document.getElementById("current").style.padding = "0";
		county = county*1 +1;//сумма сеток в штуках
		summa = summa*1 + finPrice*1;//сумма сеток в гривнах
		
		
		if (county == 1) {//добавляет первую сетку
		document.getElementById('spisok').innerHTML = county+'. Ручка оконная - '+ brand + '&nbsp; - ' + color +': ' + price + '&nbsp;' + 'грн.';
		}
	
		else if (county > 1) {//добавляет вторую и далее сетки
		document.getElementById('spisok').innerHTML = document.getElementById('spisok').innerHTML + "\n" + county +'. Ручка оконная - '+ brand + '&nbsp; - ' + color +': ' + price + '&nbsp;' + 'грн.';
		}
		
		document.getElementById('abs0').innerHTML = summa;
		document.getElementById('abs2').innerHTML = county;
		document.getElementById('result_setki').style.display = 'block';
				
	}	
	function sendRuczki(){
		
		//goog_report_conversion(); //отсылаем конверсию в гугл
		
		var dream = $("#gogo").val();

		if (dream.length <5){
			return answerNotCorrectNumber(); //выводим сообщение об ошибке
		}
		else{
				$.ajax({
					type:'get',
					url:'/../ajax',
					success: function(data){
							
							var current = CorrectAnswer(data); //получаем номер заказа
							var podokonnik_tel = $("#gogo").val();					
							var podokonnik_mail = $("#gogo1").val();

							var podokonnik_oplata = $('input[name="optionsRadiosуr"]:checked').val();
							var podokonnik_dostavka = $('input[name="optionsRadios"]:checked').val();				
							var podokonnik_spisok_zakazov = $("#spisok").val();
							
							clearspisok();
							console.log('podokonnik_tel: '+podokonnik_tel+' current: '+current+' podokonnik_mail: '+podokonnik_mail+' podokonnik_oplata: '+podokonnik_oplata+' podokonnik_dostavka: '+podokonnik_dostavka+' podokonnik_spisok_zakazov:'+podokonnik_spisok_zakazov);
							
							if (podokonnik_tel.length >4){
									$.ajax({
										type:'post',//тип запроса: get,post либо head
										url:'/../ajax5',//url адрес файла обработчика
										data:{'current': current, 
										'podokonnik_tel': podokonnik_tel, 
										'podokonnik_mail': podokonnik_mail, 
										'podokonnik_oplata': podokonnik_oplata, 
										'podokonnik_dostavka': podokonnik_dostavka, 
										'podokonnik_spisok_zakazov': podokonnik_spisok_zakazov								
										},//параметры запроса
										success: function(data){
											
											console.log(data);
										}
									});
						
							}
					}
				});				
		}				
						
						
	}					