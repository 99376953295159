// Пеллеты главная функция

	function calculatePellet_v2(){
		document.getElementById("skidka_30").style.height = "1px";        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(600);
		
		//собираем данные для подсчета
		var pellet = getPelletForm();
		
		//получаем цену товара за 1 кг
		pellet.price = getPelletPrice(pellet.type, pellet.diametr, pellet.upakovka, pellet.ves);
		console.log(pellet.price);
		
		//получаем цену доставки
		pellet.dostavkaPrice = getDostavkaPelletPrice(pellet);
		//console.log('dostavka price is: '+pellet.dostavkaPrice);
		if (pellet.price=="нет в наличии") return document.getElementById('out_1').innerHTML = "нет в наличии";
		//считаем финальную цену
		pellet.finPrice = calcRufPrice(pellet.ves, pellet.price);
		console.log(pellet.finPrice);
		
		//вывод цены
		document.getElementById('out_1').innerHTML = pellet.finPrice;
		document.getElementById('out_2').innerHTML = pellet.dostavkaPrice;
	}
	
	
	
	//РУФ - собираем данные из формы калькулятора
	
	function getPelletForm(){
		
		var ves =           $('#ves_pellet').val();
		var type =          $('#type_pellet').val();
		var diametr =       $('#diametr_pellet').val();
		var upakovka =      $('#upakovka_pellet').val();
		var dostavka =      $('#dostavka_pellet').val();
		var finPrice =      $('#out_1').text();
		var dostavkaPrice = $('#out_2').text();			
		var phone =         $("#pelphone").val();
		var name =          $("#pelname").val();
		
		var typeText = document.getElementById("type_pellet");
		typeText = typeText.options[typeText.selectedIndex].text;
		console.log(typeText);
		
		var vesText = document.getElementById("ves_pellet");
		vesText = vesText.options[vesText.selectedIndex].text;
		console.log(vesText);
		
		var upakovkaText = document.getElementById("upakovka_pellet");
		upakovkaText = upakovkaText.options[upakovkaText.selectedIndex].text;
		console.log(upakovkaText);
		
		var diametrText = document.getElementById("diametr_pellet");
		diametrText = diametrText.options[diametrText.selectedIndex].text;
		console.log(diametrText);		
		
		var dostavkaText = document.getElementById("dostavka_pellet");
		dostavkaText = dostavkaText.options[dostavkaText.selectedIndex].text;
		console.log(dostavkaText);
		
		var pellet = { 
			ves: ves,
			vesText: vesText,
			type: type,
			diametr: diametr,
			diametrText: diametrText,
			typeText: typeText,
			upakovka: upakovka,
			upakovkaText: upakovkaText,
			finPrice: finPrice,
			dostavka: dostavka,
			dostavkaPrice: dostavkaPrice,
			dostavkaText: dostavkaText,
			phone: phone,
			name: name			
		}
		
		return pellet;	
	}
	
	
	// РУФ - функция получения цены 1 кг брикетов
	
	function getPelletPrice(type, diametr, upakovka, ves){
		
		if (ves >=1 && ves <10) ves = 1;
		else if (ves >=10 && ves <22) ves = 10;
		else if (ves >=22) ves = 22;
		else ves = 1;
		
		
		//находим на странице цену в зависимости от типа и веса товара
		var text_block =type+"_"+ves+"_"+upakovka+"_"+diametr;
		//var text_block =type+"_"+ves;
		var price = $('#'+text_block).text();
		
		console.log("text_block ="+text_block);
		console.log("ves ="+ves);
		console.log("diametr ="+diametr);
		console.log("upakovka ="+upakovka);
		console.log("type ="+type);
		console.log("price ="+price);
		
		return price;
		
	}


	// РУФ - функция получения стоимости доставки
	
	function getDostavkaPelletPrice(data){
		var price =	'уточнить';
		if (data.dostavka == 'cамовывоз')     return  'cамовывоз';	
		else if (data.dostavka == 'уточнить') return  price;
		
		var ves = data.ves*1;
		var kof = '';
		
		//получаем коициент от тоннажа заказа
		if      (ves <= 2)                kof = 2;
		else if (ves > 2  && ves <= 5) kof = 5;
		else if (ves > 5  && ves <= 10)kof = 10;
		else if (ves > 10)                kof = 10;
		else 								 return  price;
				
		//находим на странице цену в зависимости от типа и веса товара
		var price = $('#'+data.dostavka+kof).text();
		console.log(ves);
		console.log(price);
		
		return price;		
	}

	
	// РУФ - формула стоимости брикетов
	
	function calcPelletPrice(ves, cena){		
		return ves * cena;	
	}
	

	//РУФ - отправляем заказ на почту
	
	function sendPellet(){
	
		$("#peletis_zakaz").hide();

		$.ajax({
			type:'get',
			url:'ajax',
			success: function(data){
				var current = data*1;
				$("#current").text(current);
				console.log(current);	
				
				var pellet = getPelletForm(); //получаем все данные заказа и финальную цену
			
				$("#peletis_answer").show();
				$("#dialogv52bf36ca52ec3_outputs0").hide();
			
				if ( current > 0){				
					$.ajax({
						type:'post',//тип запроса: get,post либо head
						url:'ajax_ruf',//url адрес файла обработчика
						data:{
							'current': current,
							'ves': pellet.vesText, 
							'type': pellet.typeText +", упаковка: " + pellet.upakovkaText, 
							'briket': pellet.diametrText,
							'summa': pellet.finPrice,
							'dostavka': pellet.dostavkaText,
							'dostavka_cena': pellet.dostavkaPrice,							
							'name': pellet.name,  
							'phone' : pellet.phone
						},//параметры запроса
						success: function(data){									
							console.log(data);
						}
					});
				}			
				else console.log('eror +' +current);	
			}
		})		
	}	