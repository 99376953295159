// РУФ главная функция

	function calculateRuf_v2(){
		document.getElementById("skidka_30").style.height = "1px";        
		$("#dialogv52bf36ca52ec3_outputs0").fadeIn(600);
		
		//собираем данные для подсчета
		var ruf = getRufForm();
		
		//получаем цену товара за 1 кг
		ruf.price = getRufPrice(ruf.type, ruf.ves);
		console.log(ruf.price);
		
		//получаем цену доставки
		ruf.dostavkaPrice = getDostavkaPrice(ruf);
		console.log('dostavka price is: '+ruf.dostavkaPrice);
		
		//считаем финальную цену
		ruf.finPrice = calcRufPrice(ruf.ves, ruf.price);
		console.log(ruf.finPrice);
		
		//вывод цены
		document.getElementById('out_1').innerHTML = ruf.finPrice;
		document.getElementById('out_2').innerHTML = ruf.dostavkaPrice;
	}
	
	
	
	//РУФ - собираем данные из формы калькулятора
	
	function getRufForm(){
		
		var ves = $('#ves_ruf').val();
		var type = $('#type_ruf').val();
		var typeBriket = $('#type_briket').val();
		var dostavka = $('#dostavka_ruf').val();
		var finPrice = $('#out_1').text();
		var dostavkaPrice = $('#out_2').text();			
		var phone = $("#pelphone").val();
		var name = $("#pelname").val();
		
		var typeText = document.getElementById("type_ruf");
		typeText = typeText.options[typeText.selectedIndex].text;
		console.log(typeText);
		
		var vesText = document.getElementById("ves_ruf");
		vesText = vesText.options[vesText.selectedIndex].text;
		console.log(vesText);
		
		var dostavkaText = document.getElementById("dostavka_ruf");
		dostavkaText = dostavkaText.options[dostavkaText.selectedIndex].text;
		console.log(dostavkaText);
		
		var ruf = { 
			ves: ves,
			vesText: vesText,
			type: type,
			typeBriket: typeBriket,
			typeText: typeText,
			finPrice: finPrice,
			dostavka: dostavka,
			dostavkaPrice: dostavkaPrice,
			dostavkaText: dostavkaText,
			phone: phone,
			name: name			
		}
		
		return ruf;	
	}
	
	
	// РУФ - функция получения цены 1 кг брикетов
	
	function getRufPrice(type, ves){
		
		var ves = ves*1;
		var kof = 0;
		var price = 0;
		
		//получаем коициент от тоннажа заказа

		if (ves >= 1  && ves < 10)  kof = 0;
		else if (ves >= 10  && ves < 22) kof = 1;
		else if (ves >= 22 ) kof = 5;
		else kof = 0;
		
		
		//находим на странице цену в зависимости от типа и веса товара
		var price = $('#'+type+kof).text()*1;
		console.log("вес =" + ves);
		console.log("коф =" + kof);
		console.log("тип =" + type);
		
		console.log("цена" + price);
		
		return price;
		
	}


	// РУФ - функция получения стоимости доставки
	
	function getDostavkaPrice(data){
		var price =	'уточнить';
		if (data.dostavka == 'cамовывоз')     return  'cамовывоз';	
		else if (data.dostavka == 'уточнить') return  price;
		
		var ves = data.ves*1;
		var kof = '';
		
		//получаем коициент от тоннажа заказа
		if      (ves <= 2000)                kof = 2;
		else if (ves > 2000  && ves <= 5000) kof = 5;
		else if (ves > 5000  && ves <= 10000)kof = 10;
		else if (ves > 10000)                return  price;
		else 								 return  price;
				
		//находим на странице цену в зависимости от типа и веса товара
		var price = $('#'+data.dostavka+kof).text();
		console.log(ves);
		console.log(price);
		
		return price;		
	}

	
	// РУФ - формула стоимости брикетов
	
	function calcRufPrice(ves, cena){		
		return ves * cena;	
	}
	

	//РУФ - отправляем заказ на почту
	
	function sendRuf(){
	
		$("#peletis_zakaz").hide();

		$.ajax({
			type:'get',
			url:'../../ajax',
			success: function(data){
				var current = data*1;
				$("#current").text(current);
				console.log(current);	
				
				var ruf = getRufForm(); //получаем все данные заказа и финальную цену
			
				$("#peletis_answer").show();
				$("#dialogv52bf36ca52ec3_outputs0").hide();
			
				if ( current > 0){				
					$.ajax({
						type:'post',//тип запроса: get,post либо head
						url:'../../ajax_ruf',//url адрес файла обработчика
						data:{
							'current': current,
							'ves': ruf.vesText, 
							'type': ruf.typeText, 
							'briket': ruf.typeBriket,
							'summa': ruf.finPrice,
							'dostavka': ruf.dostavkaText,
							'dostavka_cena': ruf.dostavkaPrice,							
							'name': ruf.name,  
							'phone' : ruf.phone
						},//параметры запроса
						success: function(data){									
							console.log(data);
						}
					});
				}			
				else console.log('eror +' +current);	
			}
		})		
	}
	
	//меняем прайс
	function changePrice(data){
		
		var ruf={};
		
		if (data=="ruf"){
			var ruf = dataRufPrice();
			ruf.data = "ruf";
		}
		else if (data=="pellet"){
			var ruf = dataPelletPrice();
			ruf.data = "pellet";
		}
			
		var my = JSON.stringify(ruf);
		console.log(ruf);
		console.log(my);
		$.ajax({

			type:'post',//тип запроса: get,post либо head
			url:'changeprice',//url адрес файла обработчика
			data: ruf,//параметры запроса
			//contentType: "application/json",
			//dataType:"json",
			success: function(data){									
				console.log(data);
				}
			});
				
	}
	
	function dataRufPrice(){
		
		var ruf = {};
		
		ruf.smeh0 = $('#smeh0').val();
		ruf.smeh1 = $('#smeh1').val();
		ruf.smeh5 = $('#smeh5').val();
		ruf.smeh10 = $('#smeh10').val();
		ruf.smeh22 = $('#smeh22').val();
		
		ruf.dub0 = $('#dub0').val();
		ruf.dub1 = $('#dub1').val();
		ruf.dub5 = $('#dub5').val();
		ruf.dub10 = $('#dub10').val();
		ruf.dub22 = $('#dub22').val();
		
		ruf.bereza0 = $('#bereza0').val();
		ruf.bereza1 = $('#bereza1').val();
		ruf.bereza5 = $('#bereza5').val();
		ruf.bereza10 = $('#bereza10').val();
		ruf.bereza22 = $('#bereza22').val();
		
		ruf.sosna0 = $('#sosna0').val();
		ruf.sosna1 = $('#sosna1').val();
		ruf.sosna5 = $('#sosna5').val();
		ruf.sosna10 = $('#sosna10').val();
		ruf.sosna22 = $('#sosna22').val();
		return ruf;
	}

	function dataPelletPrice(){
		
		var ruf = {};
		
		ruf.s_8_beg = $('#s_8_beg').val();
		ruf.b_8_beg = $('#b_8_beg').val();
		ruf.l_8_beg = $('#l_8_beg').val();
		ruf.s_8_paket = $('#s_8_paket').val();
		ruf.b_8_paket = $('#b_8_paket').val();
		ruf.l_8_paket = $('#l_8_paket').val();
		
		ruf.s_6_beg = $('#s_6_beg').val();
		ruf.b_6_beg = $('#b_6_beg').val();
		ruf.l_6_beg = $('#l_6_beg').val();
		ruf.s_6_paket = $('#s_6_paket').val();
		ruf.b_6_paket = $('#b_6_paket').val();
		ruf.l_6_paket = $('#l_6_paket').val();
		return ruf;
	}

	